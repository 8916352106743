( function( $, Foundation ) {

	if ( Foundation ) {

		// Make sure the login link has the necessary class and data attributes.
		$( '.menu__item--learn-more .menu__link' )
			.addClass( 'float-right' )
			.attr( 'data-toggle', 'blr-login-form-container' );

		// Initialize Foundation.
		$( document ).foundation();
	}

	const $body = $( 'body' );

	if ( $body.hasClass( 'tax-topic' ) ) {

		$( '.main-content' ).on( 'click', 'a[data-topic-id]', event => {

			var $target = $(event.currentTarget);

			var topicID = $target.data('topic-id');
			var libraryID = $target.data('library-id');

			if (topicID) {
				document.cookie = 'breadcrumbTopicID=' + topicID + ';path=/;';
			}

			if (libraryID) {
				document.cookie = 'breadcrumbLibraryID=' + libraryID + ';path=/;';
			}
		} );
	}
} )( window.jQuery, window.Foundation, window.Cookies );

(function ($) {
	$('#scroll-up-button-link').click(function (e) {
		e.preventDefault();
		$("html, body").animate({scrollTop: 0}, "slow");
	});
})(window.jQuery);
